import ewen from "Icons/team/ewen.jpg";
import christophe from "Icons/team/christophe.jpg";

export default [{
  langID: "ewen",
  name: "Ewen MARECHAL",
  img: ewen,
}, {
  langID: "christophe",
  name: "Christophe SIMON",
  img: christophe
}/*, {
  langID: "raphael",
  name: "Raphaël LALUNG",
  // img: raphael,
}, {
  langID: "marvin",
  name: "Marvin REA",
  // img: marvin,
}*/];
