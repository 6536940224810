import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import MenuDiv from "./styles/menu";
import MenuMob from "./styles/menu.mobile";

class Menu extends Component {
  static propTypes = {
    langID: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.homePage = this.homePage.bind(this);
  }

  homePage(event, page) {
    this.props.dispatch({ type: "CHANGE_PAGE", page });
  }

  render() {
    let { format, layout, curPage } = this.props;
    let langID = `${this.props.langID}.menu`;

    let Comp = format === "mobile" ? MenuMob : MenuDiv;

    return (
      <Comp
        langID={langID}
        homePage={this.homePage}
        layout={layout}
        curPage={curPage}
      />
    );
  }
}

const mapStateToProps = state => ({
  format: state.app.format,
  layout: state.app.layout,
  curPage: state.app.page
});

export default connect(mapStateToProps)(Menu);
