/**
  PhD Student
**/

const usertype = "phdstudent";

export default {
  en: {
    // Landing
    [`landing.usertype.${usertype}`]: `PhD student`,

    // Home
    [`home.title.first.${usertype}`]: ``,
    [`home.title.second.${usertype}`]: ``,

    [`home.solution.p1.title.${usertype}`]: `Make intensive computing accessible`,
    [`home.solution.p1.subtitle.${usertype}`]: '',
    [`home.solution.p1.text.${usertype}`]: ``
  },
  fr: {
    // Landing
    [`landing.usertype.${usertype}`]: `Doctorant`,

    // Home
    [`home.title.first.${usertype}`]: ``,
    [`home.title.second.${usertype}`]: ``,

    [`home.solution.p1.title.${usertype}`]: `Rendre accessible le calcul intensif`,
    [`home.solution.p1.subtitle.${usertype}`]: '',
    [`home.solution.p1.text.${usertype}`]: ``
  }
}
