import React, { lazy, Suspense } from "react";
import Loading from "./loading";

/*** PAGES **/
const Landing = lazy(() => import("./Landing/landing"));
const Solution = lazy(() => import("./Home/solution/solution"));
const Services = lazy(() => import("./Home/services/services"));
const RoadMap = lazy(() => import("./Home/roadmap/roadmap"));
const Company = lazy(() => import("./Home/company/company"));
const Team = lazy(() => import("./Home/team/team"));
const Contact = lazy(() => import("./Home/contact/contact"));
/************/

function switchView(name, props) {
  switch (name) {
    case "landing": return  <Landing {...props} />;
    case "solution": return <Solution {...props} />;
    case "services": return <Services {...props} />;
    case "roadmap": return  <RoadMap {...props} />;
    case "team": return     <Team {...props} />;
    case "contact": return  <Contact {...props} />;
    case "company": return  <Company {...props} />;
    default:
      console.error("This page does not exist", name);
      return <Loading />;
  }
}

export default ({name, ...props}) => {
  return (
    <Suspense fallback={<Loading />}>
      {switchView(name, props)}
    </Suspense>
  );
};
