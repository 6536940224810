import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage } from 'react-intl';
import Typography from "@material-ui/core/Typography";
import styles from "./menu.css";
import HomeIcon from "@material-ui/icons/Home"

const MenuMobileDiv = ({ classes, langID, curPage }) => (
  <div className={styles.MenuMobileDiv}>
    <Typography align="center"
      className={classes.title}
    >
      {curPage === "landing" ?
        <HomeIcon className={classes.icon}/>
        :
        <FormattedMessage id={`${langID}.${curPage}`} defaultMessage={curPage}/>
      }
    </Typography>
  </div>
);

const materialStyle = theme => ({
  title: {
    fontSize: "1.2em",
    fontWeight: 600
  },
  icon: {
    verticalAlign: "middle"
  }
});

export default withStyles(materialStyle)(MenuMobileDiv);
