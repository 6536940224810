import React from "react";

import styles from "./language.css";
import frIcon from "./flags/fr.png";
import enIcon from "./flags/en.png";

const LanguageDiv = ({ style, lang, setLanguage, format }) => (
  <div className={format === "mobile" ?
    styles.LanguageMobile : styles.Language
  } style={style}>
    <div
      className={styles.Flag}
      style={{
        opacity: lang === "fr" ? 1 : 0.4
      }}
      onClick={e => setLanguage(e, "fr")}
    >
      <img className={styles.Icon} src={frIcon} alt="fr" />
    </div>
    <div
      className={styles.Flag}
      style={{
        opacity: lang === "en" ? 1 : 0.4
      }}
      onClick={e => setLanguage(e, "en")}
    >
      <img className={styles.Icon} src={enIcon} alt="fr" />
    </div>
  </div>
);

export default LanguageDiv;
