import interfaceIcon from "Icons/services/interface.png";
import optimizationIcon from "Icons/services/optimization.png";
import hpcIcon from "Icons/services/hpc.png";
import deploymentIcon from "Icons/services/deployment.png";
import supportIcon from "Icons/services/support.png";

export default {
  interface: {
    langID: "interface", // from id of react-intl language file
    icon: interfaceIcon
  },
  optimization: {
    langID: "optimization",
    icon: optimizationIcon
  },
  hpc: {
    langID: "hpc",
    icon: hpcIcon
  },
  deployment: {
    langID: "deployment",
    icon: deploymentIcon
  },
  support: {
    langID: "support",
    icon: supportIcon
  }
};
