import React from "react";

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = ({classes}) => (
  <div className={classes.divLoading}>
    <CircularProgress className={classes.loading}
      size={70}
    />
  </div>
);

const materialStyle = theme => ({
  divLoading: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    WebKitTransform: "translate(-50%, -50%)",
  },
  loading: {
    margin: theme.spacing.unit * 2,
  }
})

export default withStyles(materialStyle)(Loading);
