import ceo from './usertypes/ceo';
import business from './usertypes/business';
import investor from './usertypes/investor';
import labdirector from './usertypes/lab.director';
import phdstudent from './usertypes/phdstudent';
import techdirector from './usertypes/tech.director';
import scientist from './usertypes/scientist';
import valorization from './usertypes/valorization';
import media from './usertypes/media';
import hacker from './usertypes/hacker';
import unemployed from './usertypes/unemployed';
import startuper from './usertypes/startuper';
import other from './usertypes/other';

import services from './services';
import team from './team.js';
import roadmap from './roadmap';
import company from "./company";

const usertypes = [ceo, business, investor, valorization,
  labdirector, phdstudent, techdirector, scientist,
  media, hacker, unemployed, startuper, other
];

const general = {
  en: {
    'appbar.menu.solution': 'Solution',
    'appbar.menu.team': 'Team',
    'appbar.menu.roadmap': 'RoadMap',
    'appbar.menu.services': 'Services',
    'appbar.menu.company': 'Company',
    'appbar.menu.contact': 'Contact Us',

    'landing.usertype.title': 'We develop your scientific web applications',
    'landing.usertype.initbutton': 'I want to know more',
    'landing.usertype.subtitle1': 'What is your sector ?',
    'landing.usertype.subtitle2': 'What is your job ?',
    'landing.usertype.category.research': 'Laboratory',
    'landing.usertype.category.enterprise': 'Company',
    'landing.usertype.category.innovation': 'Innovation',
    'landing.usertype.category.other': 'Other',
    'landing.usertype.skipstep': 'Skip',

    'home.contact.name': 'Name',
    'home.contact.company': 'Company',
    'home.contact.sector': "Sector",
    'home.contact.type': "Type",
    'home.contact.subject': 'Subject',
    'home.contact.send': 'Send',
    'home.contact.later': 'Be contacted',
    'home.contact.or': 'or',

    'home.team.title': 'Our Team',

    'button.more': 'More ...',
    'button.teaser': 'Teaser',
  },
  fr: {
    'appbar.menu.solution': 'Solution',
    'appbar.menu.team': 'Équipe',
    'appbar.menu.roadmap': 'RoadMap',
    'appbar.menu.services': 'Services',
    'appbar.menu.company': 'Société',
    'appbar.menu.contact': 'Contactez Nous',

    'landing.usertype.title': 'Nous développons vos applications web scientfiques',
    'landing.usertype.initbutton': 'En savoir plus',
    'landing.usertype.subtitle1': 'Quelle est votre secteur ?',
    'landing.usertype.subtitle2': 'Quelle est votre fonction ?',
    'landing.usertype.category.research': 'Laboratoire',
    'landing.usertype.category.enterprise': 'Entreprise',
    'landing.usertype.category.innovation': 'Innovation',
    'landing.usertype.category.other': 'Autre',
    'landing.usertype.skipstep': 'Passer',

    'home.contact.name': 'Nom',
    'home.contact.company': 'Société',
    'home.contact.sector': "Secteur",
    'home.contact.type': "Type",
    'home.contact.subject': 'Objet',
    'home.contact.send': 'Envoyer',
    'home.contact.later': 'Être contacté',
    'home.contact.or': 'ou',

    'home.team.title': 'Notre Équipe',

    'button.more': 'En savoir plus',
    'button.teaser': 'Teaser'
  }
};

export default {
  fr: {
    ...general.fr,
    ...usertypes.reduce((acc, usertype) => ({...acc, ...usertype.fr}), {}),
    ...team.fr,
    ...services.fr,
    ...roadmap.fr,
    ...company.fr
  },
  en: {
    ...general.en,
    ...usertypes.reduce((acc, usertype) => ({...acc, ...usertype.en}), {}),
    ...team.en,
    ...services.en,
    ...roadmap.en,
    ...company.en
  }
}
