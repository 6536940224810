import React, { Component } from "react";
import { connect } from "react-redux";

import SwipeableViews from 'react-swipeable-views';
import View from "./view";

var lastLayout = []

class Router extends Component {

  constructor(props) {
    super(props);
    this.pageUpdated = this.pageUpdated.bind(this);
    lastLayout = props.layout;
  }

  pageUpdated(index, indexLatest, meta) {
    let { layout } = this.props;
    // index is wrong when the swipeable views array has been modified
    // so the page has been updated (no need to update it)
    if(lastLayout.length === layout.length) {
      this.props.dispatch({
        type: "CHANGE_PAGE",
        page: layout[index]
      });
    } else lastLayout = layout;
  }

  render() {
    let { page, format, indexPage, layout } = this.props
    let langID = "home";

    if(format === "mobile") {
      return (
          <SwipeableViews enableMouseEvents
            style={{height: "100%", width: "100vw"}}
            containerStyle={{
              height: "100%",
              width: "100%"
            }}
            index={indexPage}
            onChangeIndex={this.pageUpdated}
          >
            {layout.map((name, index) =>
              <View key={index.toString()}
                name={name}
                langID={langID}
              />
            )}
          </SwipeableViews>
      );
    } else return (
        <View name={page}
          langID={langID}
        />
    );
  }
}

const mapStateToProps = state => ({
  page: state.app.page,
  indexPage: state.app.indexPage,
  format: state.app.format,
  layout: state.app.layout
});

export default connect(mapStateToProps)(Router);
