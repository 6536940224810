exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._6Ohsat32lTi94qKzJcfVH {\n  /* position: relative; */\n  position: sticky;\n  top: 0;\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  height: 60px;\n  z-index: 10;\n}\n\n._2T8SgE9EoPGrcDxziW2YZJ {\n  height: 100%;\n  margin-left: 30px;\n}\n\n._1qqxkpsKCnhnMrlsOqVrYx {\n  margin-left: 0;\n}\n", ""]);

// exports
exports.locals = {
	"AppBar": "_6Ohsat32lTi94qKzJcfVH",
	"Logo": "_2T8SgE9EoPGrcDxziW2YZJ",
	"LogoMobile": "_1qqxkpsKCnhnMrlsOqVrYx _2T8SgE9EoPGrcDxziW2YZJ"
};