const company = 'home.company';

export default {
  en: {
    [`${company}.title`]: 'Company',
    [`${company}.information`]: 'Contact Information',
    [`${company}.partners.title`]: `Our Partners`,
  },
  fr: {
    [`${company}.title`]: 'Société',
    [`${company}.information`]: 'Coordonnées',
    [`${company}.partners.title`]: `Nos Partenaires`,
  }
}
