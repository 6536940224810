const team = 'home.team'

export default {
  fr: {
    [`${team}.ewen.office`]: 'Directeur Général',

    [`${team}.christophe.office`]: 'Administrateur Base de Données',
  },
  en: {
    [`${team}.ewen.office`]: 'CEO',

    [`${team}.christophe.office`]: 'Database Administrator',
  }
}
