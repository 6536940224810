import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage } from 'react-intl';
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import styles from "./menu.css";

const MenuDiv = ({ classes, langID, homePage, curPage, layout }) => (
  <div className={styles.MenuDiv}>
    {layout.map((part, index) =>
      <Button className={classes.button}
        key={index.toString()}
        style={ curPage === part ? {backgroundColor: '#c3e4ff'} : null}
        onClick={e => homePage(e, part)}
      >
        {index === 0 ?
          <HomeIcon />
          : <FormattedMessage id={`${langID}.${part}`} defaultMessage={part}/>
        }
      </Button>
    )}
  </div>
);

const materialStyle = theme => ({
  button: {
    flexGrow: 0,
    flexShrink: 0,
    '&:hover': {
      backgroundColor: 'rgba(209, 209, 209, 1)'
    }
  }
});

export default withStyles(materialStyle)(MenuDiv);
