import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppBarDiv from './styles/appbar';

class AppBar extends Component {
  render() {
    let {userType, format} = this.props;
    let langID = "appbar";

    return <AppBarDiv langID={langID}
      userType={userType}
      format={format}
    />;
  }
};

const mapStateToProps = state => ({
  userType: state.app.userType,
  format: state.app.format
})

export default connect(mapStateToProps)(AppBar);
