import React from "react";

import Menu from "../menu/menu";
import Language from "../language/language";
import styles from "./appbar.css";
import SkalesLogo from "./logo";

const AppBarDiv = ({ langID, userType, format }) => (
  <div
    className={styles.AppBar}
    style={{backgroundColor: "white"}}
  >

    <SkalesLogo className={format === "mobile" ?
      styles.LogoMobile : styles.Logo
    }
      color="#000"
    />
    <Menu langID={langID} />
    <Language />
    {/*<div className={styles.Left}>
    </div>
    <div className={styles.Center}>
    </div>

    <div className={styles.Right}>
    </div>*/}
  </div>
);

export default AppBarDiv;
