/**
  RoadMap
**/

const roadmap = "home.roadmap";

export default {
  en: {
    [`${roadmap}.title1`]: 'Where we come from',
    [`${roadmap}.title2`]: 'Where we are going',
    [`${roadmap}.timer.days`]: "Days",
    [`${roadmap}.timer.hours`]: 'Hours',
    [`${roadmap}.timer.minutes`]: "Minutes",
    [`${roadmap}.timer.seconds`]: 'Seconds',
  },
  fr: {
    [`${roadmap}.title1`]: 'D\'où nous venons',
    [`${roadmap}.title2`]: 'Où nous allons',
    [`${roadmap}.timer.day`]: "Jours",
    [`${roadmap}.timer.hours`]: 'Heures',
    [`${roadmap}.timer.minutes`]: "Minutes",
    [`${roadmap}.timer.seconds`]: 'Secondes',
    [`${roadmap}.map.upcomingevent`]: 'Prochain Évenement',

    [`${roadmap}.map.dateitem.incubation.text`]: `Début du GenesisLab.
      Ce parcours entrepreneurial de 41 jours nous a été l’occasion de
      réaliser notre business plan et notre road-map opérationnelle à 12 mois.`,

    [`${roadmap}.map.dateitem.creation.text`]: `Création de la société et
      emménagement dans nos locaux d’Orsay, au coeur du cluster scientifique
      et technologique Paris-Saclay.`,

    [`${roadmap}.map.dateitem.spring2018.text`]: `Premier salon, et présentation
     de notre solution au publique grâce au travail et efforts de nos stagiaires
     Marvin et Lucas.`,

    [`${roadmap}.map.dateitem.spring2018.text`]: `Premier salon, et présentation
      de notre solution au publique grâce au travail et efforts de nos stagiaires
      Marvin et Lucas.`,

    [`${roadmap}.map.dateitem.frenchtech2018.text`]: `SKALES bénéficie de la
      bourse French Tech ! Merci à bpifrance pour le financement de notre
      programme d’innovation.`,

    [`${roadmap}.map.dateitem.rdvcarnot2018.text`]: `SKALES faisait partie des
      25 entreprises innovantes mises en avant sur le salon. Deux jours
      intenses, avec plus de 40 rendez-vous programmés.`,
  }
}
