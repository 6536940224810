exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._32wxoAf8JeY4Fsr6_P-C1R {\n  display: flex;\n  flex-flow: row nowrap;\n  margin-right: 30px;\n}\n\n._2_O5iG8XggrXolq9-fmjfs {\n  margin-right: 0;\n}\n\n._2ZHqj3HoaxvNNmWdgJ4j6w {\n  margin-right: 5px;\n  padding: 5px;\n  border-radius: 6px;\n}\n\n._2ZHqj3HoaxvNNmWdgJ4j6w:hover {\n  cursor: pointer;\n}\n\n._2ZHqj3HoaxvNNmWdgJ4j6w:hover img {\n  filter: brightness(1.3);\n}\n\n._81gCNlvS1AdHzuhpEHbMG {\n  display: block;\n  height: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"Language": "_32wxoAf8JeY4Fsr6_P-C1R",
	"LanguageMobile": "_2_O5iG8XggrXolq9-fmjfs _32wxoAf8JeY4Fsr6_P-C1R",
	"Flag": "_2ZHqj3HoaxvNNmWdgJ4j6w",
	"Icon": "_81gCNlvS1AdHzuhpEHbMG"
};