import React, { Component } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import language from "./conf/language/index";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import AppBar from "./Views/AppBar/appbar";
import styles from "./styles/app.css";
import Router from "./Views/router";

class App extends Component {
  constructor(props) {
    super(props);
    document.title = "SKALES";
  }

  componentDidMount() {
    window.onresize = () => {
      if (this.props.format === "desktop" && window.innerWidth < 910) {
        this.props.dispatch({
          type: "CHANGE_FORMAT",
          format: "mobile"
        });
      } else if (this.props.format === "mobile" && window.innerWidth > 910) {
        this.props.dispatch({
          type: "CHANGE_FORMAT",
          format: "desktop"
        });
      }
    };
  }

  render() {
    let { lang, palette } = this.props;

    const materialTheme = createMuiTheme({
      palette,
      typography: {
        useNextVariants: true,
        fontFamily: "\"Linux Biolinum O\", arial",
        fontSize: 15,
        fontWeightLight: 400,
        fontWeightMedium: 600,
        fontWeightRegular: 500,
      }
    });

    console.log(materialTheme);

    return (
      <MuiThemeProvider theme={materialTheme}>
        <IntlProvider key={lang} locale={lang} messages={language[lang]}>
          <div className={styles.App}>
            <AppBar />
            <Router />
          </div>
        </IntlProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  format: state.app.format,
  lang: state.locale.lang,
  userType: state.app.userType,
  palette: state.app.palette
});

export default connect(mapStateToProps)(App);
