/**
  Laboratory Director
**/

const usertype = "labdirector";

export default {
  en: {
    // Landing
    [`landing.usertype.${usertype}`]: `Laboratory Director`,

    // Home
    [`home.title.first.${usertype}`]: `You want to broadcast
      the software production of your teams
      but you are not going to become a publisher`,
    [`home.title.second.${usertype}`]: `We help you to value
      your algorithms in the form of web service`,

    [`home.solution.p1.title.${usertype}`]: `Simtopy, a digital laboratory, showcases your expertise`,
    [`home.solution.p1.subtitle.${usertype}`]: '',
    [`home.solution.p1.text.${usertype}`]: ``
  },
  fr: {
    // Landing
    [`landing.usertype.${usertype}`]: `Directeur de Laboratoire`,

    // Home
    [`home.title.first.${usertype}`]: `Vous souhaitez diffuser
      la production logicielle de vos équipes
      mais vous n’avez pas vocation à devenir éditeur`,
    [`home.title.second.${usertype}`]: `Nous vous aidons à valoriser
      vos algorithmes sous la forme de service web`,

    [`home.solution.p1.title.${usertype}`]: `Simtopy, un laboratoire numérique, vitrine de votre expertise`,
    [`home.solution.p1.subtitle.${usertype}`]: '',
    [`home.solution.p1.text.${usertype}`]: ``
  }
}
