const services = 'home.services';

export default {
  en: {
    [`${services}.interface.title`]: 'WEB Interface',
    [`${services}.interface.description`]: `Example`,

    [`${services}.optimization.title`]: 'Performance Optimization',
    [`${services}.optimization.description`]: 'Example',

    [`${services}.hpc.title`]: 'HPC Service',
    [`${services}.hpc.description`]: 'Example',

    [`${services}.deployment.title`]: 'Distributed or local network deployment',
    [`${services}.deployment.description`]: 'Example',

    [`${services}.support.title`]: 'Technical Support',
    [`${services}.support.description`]: 'Example',
  },
  fr: {
    [`${services}.interface.title`]: 'Interface WEB',
    [`${services}.interface.description`]: `Exemple`,

    [`${services}.optimization.title`]: 'Optimisation des performances',
    [`${services}.optimization.description`]: 'Exemple',

    [`${services}.hpc.title`]: 'Service HPC',
    [`${services}.hpc.description`]: 'Exemple',

    [`${services}.deployment.title`]: 'Déploiement sur réseau distribué ou local',
    [`${services}.deployment.description`]: 'Exemple',

    [`${services}.support.title`]: 'Support Technique',
    [`${services}.support.description`]: 'Exemple',
  }
}
