import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import reducer from './conf/reducer';
// Font
// import "typeface-hind";

import App from './app';

addLocaleData(en);
addLocaleData(fr);

const store = (process.env.NODE_ENV === "development" ?
  createStore(reducer, applyMiddleware(logger))
  : createStore(reducer)
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
