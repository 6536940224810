const initialState = {
  lang: 'fr'
};

const localeReducer = function(state = initialState, action) {
  switch(action.type) {
    case 'SET_LANGUAGE':
      return Object.assign({}, state, {
        lang: action.language
      });

    default:
      return state;
  }
}

export default localeReducer;
