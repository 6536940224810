import { combineReducers } from "redux";
import localeReducer from './locale.reducer';

import services from '../conf/services';
import members from '../conf/members';

let device = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) ? "mobile" : "desktop"

const palette = {
  secondary: {
    light: "rgb(6, 212, 129)",
    main: "rgb(0, 177, 106)",
    dark: "rgb(0, 112, 67)"
  },
  primary: {
    light: "rgb(0, 192, 240)",
    main: "rgb(0, 159, 199)",
    dark: "rgb(0, 95, 119)"
  },
  neutral: "#323232"
}

const server = {
  hostName: "localhost:4000"
}

const initialState = {
  device, palette, server, members,
  format: device === "desktop" ? (window.innerWidth < 910 ? "mobile" : "desktop") : device,

  userCat: '',
  userType: '',

  layout: ["landing", 'company', 'contact'],
  page: "landing", // used as index for desktop version
  indexPage: 0, // index for mobile version
  services: ["optimization"],

  selectedDate: {
    title: '',
    date: null,
    timeline: -1,
    id: -1
  }
}

const reducer = function(state =initialState, action) {
  switch(action.type) {

    case "GOTO_LANDING_PAGE":
      return initialState;

    case 'SET_USER_CATEGORY':
      return Object.assign({}, state, {
        userCat: action.userCat
      })

    case 'SET_USER_TYPE':
      return Object.assign({}, state, {
        userType: action.userType,
        layout: action.layout,
        services: action.services.map(name => services[name]),
        page: action.page,
        indexPage: action.layout.indexOf(action.page)
      })

    case 'SELECT_DATE':
      return Object.assign({}, state, {
        selectedDate: action.date
      })

    case 'CHANGE_PAGE':
      return Object.assign({}, state, {
        page: action.page,
        indexPage: state.layout.indexOf(action.page)
      })

    case 'CHANGE_FORMAT':
      if(device !== "mobile") {
        return Object.assign({}, state, {
          format: action.format
        });
      } else return state;

    default:
      return state;
  }
}

const combinedReducers = combineReducers({
  app: reducer,
  locale: localeReducer
})

export default combinedReducers;
