import React, { Component } from 'react';
import { connect } from 'react-redux';
import LanguageDiv from './styles/language';

class Language extends Component {
  constructor(props) {
    super(props);
    this.setLanguage = this.setLanguage.bind(this);
  }

  setLanguage(event, language) {
    this.props.dispatch({type: 'SET_LANGUAGE', language});
  }

  render() {
    let { lang, style, format } = this.props;

    return (
      <LanguageDiv
        style={style}
        lang={lang}
        format={format}
        setLanguage={this.setLanguage}
      />
    )
  }
};

const mapStateToProps = state => ({
  lang: state.locale.lang,
  format: state.app.format
})

export default connect(mapStateToProps)(Language);
